import './NavigationList.scss';

import {
  getCountryNameTranslation,
  isDefaultBrand,
  useConfig,
  useCountryStringUrlResolver,
  useLocalStorage,
} from '@travelwin/core';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Link, useNavigate } from '../../../components/Link';
import LoginSignUpModal from '../../../components/LoginSignUpModal';
import { ROUTE_DEFAULT, STORAGE_ROUTE_KEY } from '../../../constants';
import { useUser } from '../../../context/User';
import { useExternalHomepageConfig } from '../../../hooks/useExternalHomepageConfig';
import { isRemix } from '../../../utils/ssrUtils';
import ArrowDownIcon from './ArrowDownIcon';

interface NavigationItem {
  title: string;
  isDropdown: boolean;
  list: React.ReactNode[];
}
const className = 'c-NavigationList';

const NavigationItem = ({ isDropdown, title, list }: NavigationItem) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleDropDown = () => {
    if (isDropdown) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <section className={`${className}__section`}>
      <div
        className={classNames(`${className}__title`, {
          [`${className}__title--dropdown`]: isDropdown,
          [`${className}__title--open`]: isOpen,
        })}
        onMouseUp={handleDropDown}
        onFocus={handleDropDown}
      >
        <p>{title}</p>

        {isDropdown && <ArrowDownIcon />}
      </div>

      <ul
        className={classNames(`${className}__list`, {
          [`${className}__list--open`]: isOpen,
        })}
      >
        {list.map((content, index) => (
          <li className={`${className}__item`} key={`${title}-${index}`}>
            {content}
          </li>
        ))}
      </ul>
    </section>
  );
};

const NavigationList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    CONTACT_US_EMAIL_ADDRESS,
    CONTACT_US_PHONE_NUMBER,
    WHITELABEL_BRAND,
  } = useConfig();
  const { getLocalizedZendeskUrl, getHomepageUrl } =
    useExternalHomepageConfig();
  const { isAuthenticated, signOut } = useUser();
  const [isShowLogin, setIsShowLogin] = useState(false);
  const [redirectPath, setRedirectPath] = useLocalStorage(STORAGE_ROUTE_KEY);
  const countryUrlResolver = useCountryStringUrlResolver();

  const handleCompleteLogin = () => {
    setIsShowLogin(false);
    const redirectRoute = redirectPath?.redirectAfterAuth
      ? redirectPath.redirectAfterAuth
      : ROUTE_DEFAULT;
    setRedirectPath(null);
    navigate(redirectRoute);
  };

  const phoneNumberRaw = `+${CONTACT_US_PHONE_NUMBER.replace(/\s/g, '')
    .replace(/\)/g, '')
    .replace(/\(/g, '')
    .replace(/-/g, '')
    .replace(/^\+/, '')}`;

  const contactContent: NavigationItem = {
    title: t('footer.navigation.title.contact', 'Contacts:'),
    isDropdown: false,
    list: [
      <a
        key="mail"
        href={`mailto:${CONTACT_US_EMAIL_ADDRESS}`}
        className={`${className}__link`}
      >
        {CONTACT_US_EMAIL_ADDRESS}
      </a>,
      <a
        key="phone"
        href={`tel:${phoneNumberRaw}`}
        className={`${className}__link`}
      >
        {CONTACT_US_PHONE_NUMBER}
      </a>,
    ],
  };

  const anchorWrapper = (url: string, text: string) => (
    <a
      className={`${className}__link`}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  );

  const planSelectionLink = (countryCode: string) => {
    const countryDescription = `countries.${countryCode}`;
    const urlName = countryUrlResolver(countryDescription);
    return (
      <Link className={`${className}__link`} to={`/plan-selection/${urlName}`}>
        {getCountryNameTranslation(countryDescription)}
      </Link>
    );
  };

  const contents: NavigationItem[] = isDefaultBrand(WHITELABEL_BRAND)
    ? [
        {
          title: t('footer.navigation.title.destination', 'Destination'),
          isDropdown: true,
          list: ['GB', 'CA', 'IE', 'MX', 'TR', 'IN', 'US', 'PK'].map(
            planSelectionLink,
          ),
        },
        {
          title: t('navbar.shop', 'Shop'),
          isDropdown: true,
          list: [
            isRemix() ? (
              <Link className={`${className}__link`} to="/" key="home">
                {t('footer.navigation.home', 'Home')}
              </Link>
            ) : (
              <a
                className={`${className}__link`}
                key="home"
                href={getHomepageUrl()}
              >
                {t('footer.navigation.home', 'Home')}
              </a>
            ),
            isRemix() ? (
              <Link
                className={`${className}__link`}
                to="/destinations"
                key="destinations"
              >
                {t('footer.navigation.destinations', 'Destinations')}
              </Link>
            ) : (
              <a
                className={`${className}__link`}
                key="destinations"
                href={getHomepageUrl('/destinations')}
              >
                {t('footer.navigation.destinations', 'Destinations')}
              </a>
            ),
            <Link key="my_esim" className={`${className}__link`} to="/orders">
              {t('navbar.my_esim', 'My eSIM')}
            </Link>,
            <Link
              key="my_account"
              className={`${className}__link`}
              to="/my-account"
            >
              {t('navbar.my_account', 'My Account')}
            </Link>,
          ],
        },
        {
          title: t('navbar.help', 'Help'),
          isDropdown: true,
          list: [
            anchorWrapper(
              getLocalizedZendeskUrl(),
              t('navbar.faqs', 'Help Center'),
            ),
            anchorWrapper(
              getHomepageUrl('/store-locations'),
              t('navbar.store_location', 'Find a Store'),
            ),
            anchorWrapper(
              getHomepageUrl('/travel-esim'),
              t('navbar.esim_travel', 'eSIM Travel'),
            ),
            anchorWrapper(
              getHomepageUrl('/support/esim-activation-guide'),
              t('navbar.activation_guide', 'eSIM Activation Guides'),
            ),
            anchorWrapper(
              getHomepageUrl('/support/compatible-handsets'),
              t('navbar.compatible_handsets', 'eSIM Compatible Phones'),
            ),
            anchorWrapper(
              getHomepageUrl('/support/phone-unlocking'),
              t('navbar.phone_unlocking', 'Phone Unlocking'),
            ),
            anchorWrapper(
              getHomepageUrl('/support/contact-us'),
              t('navbar.contact_us', 'Customer Support'),
            ),
          ],
        },
        contactContent,
      ]
    : [
        {
          title: t('navbar.shop', 'Shop'),
          isDropdown: false,
          list: [
            <Link key="my_esim" className={`${className}__link`} to="/orders">
              {t('navbar.my_esim', 'My eSIM')}
            </Link>,
            isAuthenticated ? (
              <div
                key="log_out"
                role="button"
                className={`${className}__link`}
                onMouseUp={() => signOut()}
                onFocus={() => signOut()}
              >
                {t('navbar.logout', 'Log Out')}
              </div>
            ) : (
              <div
                key="log_in"
                role="button"
                className={`${className}__link`}
                onMouseUp={() => setIsShowLogin(true)}
                onFocus={() => setIsShowLogin(true)}
              >
                {t('navbar.login', 'Log In')}
              </div>
            ),
            <Link
              key="my_account"
              className={`${className}__link`}
              to="/my-account"
            >
              {t('navbar.my_account', 'My Account')}
            </Link>,
          ],
        },
        contactContent,
      ];

  return (
    <div className={className}>
      {contents.map((navigationItem) => (
        <NavigationItem key={navigationItem.title} {...navigationItem} />
      ))}

      <LoginSignUpModal
        on={isShowLogin}
        toggle={() => setIsShowLogin(false)}
        handleCompleted={handleCompleteLogin}
      />
    </div>
  );
};

export default NavigationList;
