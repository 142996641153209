import './Footer.scss';

import { Icon, useConfig, useGA4 } from '@travelwin/core';
import { Trans, useTranslation } from 'react-i18next';

import { ANALYTICS_EVENT_TYPES } from '../../analytics';
import { useExternalHomepageConfig } from '../../hooks/useExternalHomepageConfig';
import { getBrandSpecificImage, isDefaultBrand } from '../../utils';
import { isRemix } from '../../utils/ssrUtils';
import { Link } from '../Link';
import { AppStore, GooglePlayStore } from '../MobileStoreButton';
import { NavigationList } from './components';

const Footer = () => {
  const className = 'c-Footer';
  const {
    WHITELABEL_BRAND,
    PRIVACY_POLICY_URL,
    TERMS_AND_CONDITIONS_URL,
    SHOW_STRIPE_CREDIT_CARD,
    BRAND_NAME,
  } = useConfig();

  const { getHomepageUrl, getLocalisedUrl } = useExternalHomepageConfig();
  const { t } = useTranslation();
  const { trackEvent } = useGA4();

  const getPaymentTitle = (provider: string) => {
    return t('payment_method.provider', '{{provider}} Payment Method', {
      provider,
    });
  };

  return (
    <div className={className}>
      <div className={`${className}__container`}>
        <div className={`${className}__top-container`}>
          <div className={`${className}__logo`}>
            <img
              src={getBrandSpecificImage('logoWhite', WHITELABEL_BRAND)}
              alt={t('logo.altText', '{{brandName}} Logo', {
                brandName: BRAND_NAME,
              })}
              width={128}
              height={64}
            />
          </div>
          {isDefaultBrand(WHITELABEL_BRAND) && (
            <div className={`${className}__stores-buttons`}>
              <AppStore
                onClick={() =>
                  trackEvent(
                    ANALYTICS_EVENT_TYPES.DirectDownloadAppstoreSelected,
                  )
                }
              />
              <GooglePlayStore
                onClick={() =>
                  trackEvent(
                    ANALYTICS_EVENT_TYPES.DirectDownloadPlaystoreSelectedPlay,
                  )
                }
              />
            </div>
          )}
        </div>

        <div className={`${className}__navigation-container`}>
          <NavigationList />
        </div>

        <div className={`${className}__payment-sns-container`}>
          <div className={`${className}__payments`}>
            {SHOW_STRIPE_CREDIT_CARD && (
              <>
                <Icon name="stripe" title={getPaymentTitle('Stripe')} />
                <Icon name="googlePay" title={getPaymentTitle('Google Pay')} />
                <Icon name="applePay" title={getPaymentTitle('Apple Pay')} />
                <Icon name="payPal" title={getPaymentTitle('Paypal')} />
              </>
            )}
          </div>

          {isDefaultBrand(WHITELABEL_BRAND) && (
            <div className={`${className}__sns`}>
              <a
                href={'https://www.facebook.com/simlocal/'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="facebook" />
              </a>
              <a
                href={'https://www.instagram.com/simlocal/?hl=en-gb'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="instagram" />
              </a>
              <a
                href={'https://twitter.com/simlocal'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="x" />
              </a>
              <a
                href={'https://www.linkedin.com/company/sim-local'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="linkedIn" />
              </a>
              <a
                href={'https://www.youtube.com/@simlocal_esim'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="youtube" />
              </a>
              <a
                href={'https://www.tiktok.com/@simlocal_esim'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="tikTok" />
              </a>
            </div>
          )}
        </div>

        <div className={`${className}__footer`}>
          {isDefaultBrand(WHITELABEL_BRAND) && (
            <div className={`${className}__about-us`}>
              <a
                className={`${className}__link-article`}
                href={getHomepageUrl('/about-us/about-sim-local')}
                rel="noopener noreferrer"
              >
                {t('navbar.about_sim_local', 'About Sim Local')}
              </a>
              <p>|</p>
              <a
                className={`${className}__link-article`}
                href={getHomepageUrl('/about-us/careers')}
                rel="noopener noreferrer"
              >
                {t('navbar.careers', 'Careers')}
              </a>
              <p>|</p>
              <a
                className={`${className}__link-article`}
                href={getHomepageUrl('/partners')}
                rel="noopener noreferrer"
              >
                {t('navbar.partners', 'Partners')}
              </a>
              <p>|</p>
              <a
                className={`${className}__link-article`}
                href={getHomepageUrl('/affiliates')}
                rel="noopener noreferrer"
              >
                {t('footer.about_us.affiliates', 'Affiliates')}
              </a>
              <p>|</p>
              {isRemix() ? (
                <Link to="/news" className={`${className}__link-article`}>
                  {t('navbar.news', 'News')}
                </Link>
              ) : (
                <a
                  className={`${className}__link-article`}
                  href={getHomepageUrl('/news')}
                  rel="noopener noreferrer"
                >
                  {t('navbar.news', 'News')}
                </a>
              )}
            </div>
          )}
          <div className={`${className}__policy`}>
            <div className={`${className}__policy-links`}>
              <a
                className={`${className}__link`}
                href={getLocalisedUrl(PRIVACY_POLICY_URL)}
                rel="noopener noreferrer"
              >
                {t('footer.privacy_policy', 'Privacy Policy')}
              </a>
              {isDefaultBrand(WHITELABEL_BRAND) && (
                <a
                  className={`${className}__link`}
                  href={getHomepageUrl('/cookie-policy')}
                  rel="noopener noreferrer"
                >
                  {t('footer.cookies_settings', 'Cookies Settings')}
                </a>
              )}
              <a
                className={`${className}__link`}
                href={getLocalisedUrl(TERMS_AND_CONDITIONS_URL)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('footer.terms_and_conditions', 'Terms & Conditions')}
              </a>
            </div>
            <p className={`${className}__all-rights-reserved`}>
              <Trans
                i18nKey="footer.text"
                defaults={
                  '© {{year}} Sim Local (IRELAND) Ltd. All Rights Reserved.'
                }
                values={{ year: new Date().getFullYear() }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
